import Project from "./Project";
import sketchConnect from "../assets/sketch-connect.png"
import impromptu from "../assets/impromptu.png"
import pepper from "../assets/pepper.png"

export default function Projects() {
  return (
    <div className="section" id="projects">
      <div className="px-4 sm:px-0">
        <h2 className="title text-base font-semibold leading-7 text-gray-900">
          Projects
        </h2>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <Project
            title="Pepper Robot"
            date="Sep 2023 - Apr 2024"
            preview={pepper}
            description="Designing and implementing intuitive user interactions for the Pepper robot using Python, enhancing company culture.
            Pioneering the integration of AI-driven analytics into Pepper robot's sensory data processing, optimizing real-time decision-making processes."
            tech="Python"
          />
          <Project
            title="Impromptu"
            date="Oct 2023"
            preview={impromptu}
            description="Developed and integrated a NoSQL Firebase backend for 'Impromptu', a mobile app designed to enhance social connectivity by prompting users with engaging questions and storing a history of user entries for reflective engagement.
            Played a pivotal role in the project's recognition, contributing to earning an Honorable Mention at DubHacks 2023"
            tech="Javascript, HTML/CSS, React Native, Firebase"
          />
          <Project
            title="SketchConnect"
            date="May 2023 - Aug 2023"
            preview={sketchConnect}
            description="Worked on the backend development of SketchConnect, a collaborative doodling application built on the MERN stack.
            Implemented robust data storage solutions using MongoDB, efficiently storing session records, user drawings, and associated metadata, optimizing query performance for a smooth user experience.
            Collaborated with the frontend team to define API endpoints and establish smooth data flow, enabling seamless synchronization of user interactions and real-time updates across the application."
            tech="JavaScript, HTML/CSS, React.js, Node.js, MongoDB, Express"
          />
        </dl>
      </div>
    </div>
  );
}

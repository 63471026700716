export default function Experience(props) {
  return (
    <div className="px-4 py-6 sm:grid sm:gap-4 sm:px-0">
      <dt className="text-lg font-medium leading-6 text-gray-900">{props.title}</dt>
      <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{props.location}</dd>
      <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{props.date}</dd>
      <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{props.description}</dd>

    </div>
  )
}

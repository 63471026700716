import Experience from "./Experience";

export default function WorkExperience() {
  return (
    <div className="section" id="work-experience">
      <div className="px-4 sm:px-0">
        <h2 className="title text-base font-semibold leading-7 text-gray-900">
          Work Experience
        </h2>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <Experience
            title="Software Developer Intern | SAP Concur"
            location="Vancouver, BC"
            date="Sep 2023 - Present"
            description="Engage in ongoing codebase management for SAP Concur expense applications, constructed using Java and Javascript on a SQL database, to ensure their durability, efficiency, and adaptability to evolving tech requirements and advancements.
            Drive ongoing testing automation enhancements, streamline test processes, and increase efficiency, resulting in improved software quality and faster release cycles."
          />
          <Experience
            title="Software Developer Intern | Moz"
            location="Vancouver, BC"
            date="Jan 2023 - Aug 2023"
            description="Implemented CRUD API services to enable access to search engine and brand influence data, using Golang.
            Restructured existing systems into a clean architecture, enabling separation of business logic from implementation details, enhancing maintainability and testability of the code.
            Developed CLI tools using Golang and external libraries, Cobra and Viper, streamlining data access and manipulation, empowering team members for efficient interaction with company data, reducing manual processing time by 50%."
          />
          <Experience
            title="Computer Science Teaching Assistant | University of British Columbia"
            location="Vancouver, BC"
            date="Sep 2023 - Present"
            description="Collaborated with fellow Teaching Assistants (TAs) to facilitate CPSC 110 labs and office hours, supporting students in grasping the fundamental concepts of computer programming in Racket.
            Increase student comprehension of course content by providing clear explanations of the course content."
          />
        </dl>
      </div>
    </div>
  );
}

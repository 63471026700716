import React from "react";

function About() {
  return (
    <div className="section" id="about">
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <h2 className="title text-base font-semibold leading-7 text-gray-900">
          About
        </h2>
        <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <p>
            Hello, I'm Michelle, a fourth-year Computer Science and business
            student from the University of British Columbia.
          </p>
          <br />
          <p>
            My academic and professional journey in technology has been rich and
            diverse, driven by a deep passion for software development and
            project management. Throughout my career, I have embraced
            opportunities to develop and refine my technical skills across
            various programming languages and platforms.
          </p>
          <br />
          <p>
            My experience includes impactful software development internships,
            where I contributed to both the efficiency and quality of technology
            solutions. Beyond technical skills, I am deeply involved in my
            community, particularly in nurturing the growth of aspiring
            programmers and designers.
          </p>
          <br />
          <p>
            My role as a Sponsorship Coordinator for UBC nwPlus exemplifies my
            commitment to fostering industry connections and supporting
            educational events like hackathons.
          </p>
          <br />
          <p>
            My approach to technology is characterized by a balance of technical
            acumen, collaborative teamwork, and a continuous pursuit of learning
            and growth. Whether it's in a development role or a
            community-focused position, my goal is to create and contribute to
            projects that make a meaningful impact.
          </p>
        </dd>
      </div>
    </div>
  );
}

export default About;

export default function Project(props) {
  return (
    <div className="projects">
      <div className="px-4 py-6 sm:grid sm:gap-4 sm:px-0">
        <img className="preview" src={props.preview} alt="preview"></img>
      </div>
      <div className="px-4 py-6 sm:grid sm:gap-4 sm:px-0">
        <dt className="text-xl font-large leading-6 text-gray-900">
          {props.title}
        </dt>
        <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          {props.date}
        </dd>
        <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          {props.description}
        </dd>
        <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
          {props.tech}
        </dd>
      </div>
    </div>
  );
}

import React from "react";
import LandingPage from "./LandingPage";
import WorkExperience from "./WorkExperience";
import Projects from "./Projects";
import About from "./About";

function Home() {
  return <div>
  <LandingPage />
  <WorkExperience />
  <Projects />
  <About />
  </div>
}

export default Home;
